<template>
  <el-row>
    <el-form label-width="120px">
      <el-form-item label="每周">
        <l-select v-model="dataInfo.dayA" :all="false" :data="$constant.BaseWeeks" @change="refresh()" style="width:100px"></l-select>
        <span class="padding-lr-xs">至</span>
        <l-select v-model="dataInfo.dayB" :all="false" :data="$constant.BaseWeeks" @change="refresh()" style="width:100px"></l-select>
        <span class="padding-lr-xs text-bold text-green">可执行</span>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    refresh(reset) {
      this.$emit('refresh', {
        data: this.dataInfo,
        reset: !!reset
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
